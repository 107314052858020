import React, { Fragment } from 'react'

function Founders() {

    const VolunteerData = [
        {
            id: 1,
            image: '',
            name: 'Pooran Chandra Gaur',
            details: 'President',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 2,
            image: '',
            name: 'Mahesh Lal Varma',
            details: 'Vice President',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 3,
            image: '',
            name: 'Lalit Mohan Joshi',
            details: 'Secretary',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 4,
            image: '',
            name: 'Shivendra Phuloria',
            details: 'Treasury',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 5,
            image: '',
            name: 'Hemendra Singh',
            details: 'Member',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 6,
            image: '',
            name: 'Umesh Chandra Sati',
            details: 'Member',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        },
        {
            id: 7,
            image: '',
            name: 'Hemant Gaur',
            details: 'Manager',
            FBLink: '',
            TwitterLink: '',
            InstaLink: ''
        }
    ]

    return (
        <Fragment>
            <div className="team-area section-padding">
                <div className="container">
                    <div className="auto-container">
                        <div className="row clearfix mr0">
                            <div className="col-lg-12">
                                <div data-aos="fade-down" className="about-content text-center">
                                    <div className="sec-title">
                                        <h2>Meet Our Founders/Governing Body</h2><br/><br/><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            VolunteerData.map((item) => (
                                <div className="col-lg-3 col-md-4 pad-top-50 pad-bot-50" data-aos="zoom-in" key={item.id}>
                                    <div className="single-team-member">
                                        <div className={item.image}>
                                            <div className="team-content">
                                                <div className="team-title">
                                                    <a href="/#">{item.name}</a>
                                                </div>
                                                <div className="team-subtitle">
                                                    <p>{item.details}</p>
                                                </div>
                                                <ul className="team-social">
                                                    <li>
                                                        <a href={item.FBLink}><i className="fa fa-facebook-f" aria-hidden="true"/> </a>
                                                    </li>
                                                    <li>
                                                        <a href={item.TwitterLink}><i className="fa fa-twitter" aria-hidden="true"/> </a>
                                                    </li>
                                                    <li>
                                                        <a href={item.InstaLink}><i className="fa fa-instagram" aria-hidden="true"/> </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Founders
