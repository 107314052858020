import React from 'react'

function EventDescription() {
    return (
        <div className="col-lg-8 col-md-12">
            <div className="event-details">
                <img src={require('../../../Static/img/marathonEvent.jpg')} alt=""/>
                <p className="event-date">
                    <i className="las la-calendar-check"></i>
                    October 20, 2019
                </p>
                <h6>Alakh अलख Run for Pahad</h6>
                <p className="event-meta"> 
                    <span>Gewar Ghati, Masi, Almora</span>
                </p>
                <p>In 2019, we took immense pride in orchestrating the groundbreaking 'Alakh अलख Run for Pahad' marathon—an impactful initiative designed to catalyze positive transformation in the region, advocate for a healthy lifestyle, and boost tourism in remote villages. The marathon, executed with remarkable success, garnered widespread media attention across various social media platforms, amplifying its reach and impact. This event not only exemplified our commitment to community well-being but also served as a powerful platform to highlight the beauty of the remote villages, showcasing their cultural richness and inviting greater attention to these often-overlooked areas.
                </p>
                <div>
                    <div className="col-lg-12">
                        <h5>Marathon Facebook Page</h5>
                    </div>
                    <iframe
    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMasiHalfMarathonAlakh%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
    width="340" height="500"  scrolling="no" frameBorder="0"
    allowFullScreen="true" title="Facebook page"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"/>
                    <br></br>
                </div>
                <div>
                    <div className="col-lg-12">
                        <h5>Marathon Theme Song By <a href="https://www.youtube.com/pandavaas">Pandavaas</a></h5>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Lk3LqdTWWtU?si=5BZJQAjo6PvCRPBc"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    <br></br>
                </div>
                <div>
                    <div className="col-lg-12">
                        <h5>Masi Marathon Anthem <a href="https://www.youtube.com/@gauravpandeymusic">Gaurav Pandey</a></h5>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/DVga_euMcYk?si=0k_XVvJ1NLEcee5C"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </div>
                <div>
                    <div className="col-lg-12">
                        <h5>Masi Marathon Teaser by <a href="https://www.youtube.com/@TheResponsibless">Ashish Pandey, The Responsibles</a></h5>
                    </div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/diaKHPZviDs?si=ZGYDT8RPs4i3ZwPJ"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                </div>
                <div>
                    <div className="col-lg-12">
                        <h5>Masi Marathon Short Film by <a href="https://www.youtube.com/@TheResponsibless">Ashish Pandey, The Responsibles</a></h5>
                    </div>
                <iframe width="560" height="315"
                        src="https://www.youtube.com/embed/nJNUR3qNLfw?si=P0a4M_IV0s-uvs_P&amp;start=377"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                </div>
                <h5>Media Coverage</h5>
                <p>We're also pleased to share that our event has received noteworthy media coverage, which you can explore at the following links:</p>
                <p> -  Masi Marathon Coverage by <a href="https://www.kafaltree.com/masi-marathon-a-rare-feat/">Kafal Tree</a></p>
                <p> -  Masi Marathon Coverage by <a href=" https://www.newspost.live/en/20th-october-masi-alakh-half-marathon/">News Post</a></p>
                <p> -  Masi Marathon Coverage by <a href="https://www.facebook.com/euttaranchal/posts/10157771447128336/?paipv=0&eav=AfbQMeuv311d68-gH_0mJsVNihpyzlCJrJ9S6DNPq5WvOjfGSECLWf9OPckw8X1GRDY&_rdr">Euttaranchal</a></p>
                <h5>Print Media Coverage</h5>

               </div>
        </div>

    )
}

export default EventDescription
