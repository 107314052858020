import React from 'react'

function WhyChooseUs() {
    return (
        <div className="choose-us-area section-padding">
		<div className="container">
			<div className="row">
                <div className="col-lg-12 pad-bot-25">
                <div className="about-content text-center">
                    <div className="sec-title">
                        <h2> Get Involved with MAASHA</h2>
                    </div>
                </div>
                </div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<div className="choose-us-bg">
						<img src={require('../../../Static/img/whyChooseUs.jpg')} alt=""/>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="choose-us-content">
						<p>Thank you for expressing interest in becoming a part of MAASHA's mission for positive change. There are several ways you can get involved and contribute to our efforts in creating a brighter, more sustainable future for underprivileged communities in Uttarakhand.</p>
						<div className="single-choose-item">
							<i className="las la-user-alt"></i>
							<div className="single-choose-content">
								<h6>Volunteer Opportunities</h6>
								<p>Join our team of dedicated volunteers who actively participate in education, healthcare, environmental initiatives, and more. Your time and skills can make a significant impact.</p>
							</div>
						</div>

						<div className="single-choose-item">
							<i className="las la-thumbs-up"></i>
							<div className="single-choose-content">
								<h6>Donation and Fundraising</h6>
								<p>Support our cause by making a donation or organizing a fundraising event. Every contribution helps us reach more individuals and communities in need.</p>
							</div>
						</div>

						<div className="single-choose-item">
							<i className="lar la-heart"></i>
							<div className="single-choose-content">
								<h6>Partner with Us</h6>
								<p>Collaborate with MAASHA on projects, events, or initiatives aligned with our mission. Together, we can amplify our impact and bring about lasting change.</p>
							</div>
						</div>
						<div className="single-choose-item">
							<i className="lar la-heart"></i>
							<div className="single-choose-content">
								<h6>Spread the Word</h6>
								<p>Help us raise awareness about our work. Follow us on social media, share our posts, and spread the word about MAASHA's initiatives within your network.</p>
							</div>
						</div>
						<div className="single-choose-item">
							<i className="lar la-heart"></i>
							<div className="single-choose-content">
								<h6>Join our Newsletter</h6>
								<p>Stay updated on our latest projects, events, and success stories by subscribing to our newsletter. Be a part of our community and witness the positive change you're helping to create.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    )
}

export default WhyChooseUs
