import React from 'react'
function WhatWeDo() {
    const Information = [
        {
            id:1,
            icon: 'icon flaticon-globe heart',
            title:'Education',
            description:'Empower individuals with accessible, inclusive, and quality education, enabling them to unlock their full potential and contribute to societal progress.'
        },
        {
            id:2,
            icon: 'icon flaticon-globe heart',
            title:'Environment',
            description:'Promote eco-conscious practices and collaborate with local communities to preserve biodiversity and mitigate environmental challenges, ensuring a greener and more sustainable planet for future generations.'
        },
        {
            id:3,
            icon: 'icon flaticon-globe heart',
            title:'Health',
            description:'Promote holistic health and well-being by providing essential healthcare services, preventative care, and health education, thus ensuring a healthy and thriving community.'
        },
        {
            id:4,
            icon: 'icon flaticon-globe heart',
            title:'Skill Development and  Rural Livelihood',
            description:'Facilitate economic self-sufficiency and sustainable livelihoods through skill development, vocational training, and entrepreneurship initiatives, empowering individuals to improve their standard of living.'
        },
        {
            id:5,
            icon: 'icon flaticon-globe heart',
            title:'Capacity Building',
            description:'Strengthen the capacities of individuals, communities, and local institutions to become self-reliant, resilient, and capable of driving their own sustainable development.'
        },
        {
            id:6,
            icon: 'icon flaticon-globe heart',
            title:'Community Engagement',
            description:'Foster active community participation and ownership in the development process, promoting a sense of ownership and pride in the progress and well-being of their communities.'
        }
    ];

    return (
        <section className="what-we-do">
            <div className="outer-container clearfix">
                <div className="content-column">
                    <div className="inner-box">
                        <div data-aos="fade-down" className="sec-title">
                            <h2>What We Do</h2>
                        </div>
                        <div data-aos="fade-down" className="text">
                            <p>Through our unwavering commitment to education, health, livelihood, and environmental sustainability, we aim to create a world where every individual has the opportunity to lead a dignified, healthy, and fulfilling life and where communities thrive in harmony with the environment. Together, we can build a better future for all.</p>
                        </div>
                        <ul data-aos="fade-down" className="choose-info">
                            {
                                Information.map((item, index) => (
                                    <li key={item.id}>
                                    <span className={item.icon}></span>
                                    <h3><a href="/#" className="cl1">{item.title}</a></h3>
                                    <p>{item.description}</p>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="image-column"></div>
        </section>
    )
}

export default WhatWeDo
