import React from 'react'
import { Link } from 'react-router-dom';
import PrimaryBtn from '../../../components/Buttons/PrimaryBtn';

function RecentEvents() {
    const eventsData = [
        {
            key:1,
            id:1,
            eventImage:require('../../../Static/img/pariksha.jpg'),
            eventTitle:'Khetriya Gyan Vigyan Pariksha',
            eventTime:'1:00pm to 9:00 AM',
            eventLocation:' Masi, Almora',
            eventPage:"/pariksha",
            eventDescription:'On 15 September ‘kshetryiya gyan vigyan parisksha’ competition for school students to test the holistic understanding. Students from all the schools in the Chaukhutiya block participates in the competition.'
        },
        {key:2,
            id:2,
            eventImage:require('../../../Static/img/sevaDiwas.JPG'),
            eventTitle:'Sewa Diwas',
            eventTime:'1:00pm to 9:00pm',
            eventLocation:' Masi, Almora',
            eventPage:"/sewa-diwas",
            eventDescription:'On Sewa Diwas, observed on April 14th, we distribute school supplies and uniforms, extending support to education, and provide essential goods to those in need, embodying our commitment to community service.'
        },
        {   key:3,
            id:3,
            eventImage:require('../../../Static/img/marathon.jpg'),
            eventTitle:'अलख Run for Pahad',
            eventTime:'1:00pm to 9:00pm',
            eventLocation:' Gewar Ghati, Masi, Almora',
            eventPage:"/marathon",
            eventDescription:'we proudly organized the inaugural \'Alakh Run for Pahad\' marathon, a significant event aimed at catalyzing positive transformation in the region and promoting tourism in remote villages. The marathon was conducted successfully and a lot of media attention across various social media platforms.'
        }
    ]

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix mr0">
                    <div className="col-lg-12">
                        <div data-aos="fade-down" className="about-content text-center">
                            <div className="sec-title">
                                <h2>Recent Events</h2>
                            </div>
                                <p>Empowering communities, our recent event focused on distributing essential supplies, fostering education, and creating a positive impact, embodying our commitment to social service and community well-being.</p>
                        </div>
                    </div>
                    <div className="row">
                        {
                            eventsData.map((item,) => (
                                <div className="col-md-4 mt-4" key={item.id}>
                                    <div className="card ecard event-card">
                                        <div className="card-img-block">
                                        <Link to={item.eventPage} href="/#">
                                                <img className="card-img-top" 
                                                    src={item.eventImage}
                                                    alt="event"/>
                                            </Link>
                                        </div>
                                        <div className="card-body pt-0">
                                            <h5 className="card-title">
                                                <Link to={item.eventPage}
                                                    href="/#" 
                                                    className="event_a">{item.eventTitle}</Link></h5>
                                            <h6 className="cl2"><i
                                                    className="fa fa-map-marker cl1"/> {item.eventLocation} </h6>
                                            <p>{item.eventDescription}</p>
                                            <h6>
                                                <Link to={item.eventPage} href="/#"
                                                className="cl2">Read more 
                                                </Link>
                                                    &nbsp;
                                                <i className="fa fa-arrow-right cl1"/>
                                            </h6>
                                        </div>
                                    </div>
                                    <p className="mt-3 w-100 float-left text-center"/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <PrimaryBtn ButtonText="Explore More" className="text-center" to="/events"/>
        </div>
    )
}

export default RecentEvents
