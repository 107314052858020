import React from 'react'
import ExploreBtn from '../../../components/Buttons/ExploreBtn'

function EventCatagory() {

    const catagories = [
        {
            id:1,
            icon:'fa fa-graduation-cap',
            title: 'Education',
            description:'Conduct every year ‘kshetryiya gyan vigyan parisksha’ competition for school students to test the holistic understanding. Students from all the schools in the Dwaharat block participates in the competition. \n' +
                'Stationary and Uniform distribution to the underprivileged and needy children.'
        },{
            id:2,
            icon:'fa fa-home',
            title: 'Environment',
            description:'Awareness campaign and plantation of native himalayan trees of Uttarakhand\n' +
                'Conducted a detailed research on  spring water and naula rejuvenation\n' +
                'Painting and Quiz competition are conducted every year on the occasion of World Environment Day.\n' +
                'Cleaning initiative under the Swacchh Bharat Abhiyan.\n'
        },{
            id:3,
            icon:'fa fa-gavel',
            title: 'Health',
            description:'Annually conduct health check-up camps in remote rural villages.'
        },{
            id:4,
            icon:'fa fa-users',
            title: 'Livelihood',
            description:'Conducted 3 day training of mushroom farming to raise and to diversify the income of the farmers. The training was under the guidance of   Mrs. Preeti Bhandari famously  known as Mushroom lady of  Almora.'
        }
    ];

    const infos = 
    {
        infoTitle: 'Event Categories',
        headingNormalStart:'Which kind of',
        headingSpan: 'Events',
        headingNormalEnd:' We Do.',
        paragraph:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas eligendi vitae perferendis voluptatum voluptatibus dolores architecto doloribus excepturi natus facere voluptates, nesciunt consequatur.'
    }

    return (
        <div className="event-container" >
            <div className="container">
                <div className="auto-container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content text-center" data-aos="fade-down">
                                <div className="sec-title">
                                        <h2>{infos.infoTitle}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="choose-us-area section-padding2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-us-content-area">
                                    <div className="row">
                                        {
                                            catagories.map((item, index) => (
                                                <div className="col-lg-3 col-md-6 col-sm-12" data-aos="flip-left" key={item.id}>
                                                    <div className="single-service-item">
                                                        <i className={item.icon}></i>
                                                        <h5>{item.title}</h5>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ExploreBtn/>
            </div>
        </div>
    )
}

export default EventCatagory
