import React from 'react'

function EventDescription() {
    return (
        <div className="col-lg-8 col-md-12">
            <div className="event-details">
                <img src={require('../../../Static/img/sewaDiwasEvent.jpg')} alt=""/>
                <p className="event-date">
                    <i className="las la-calendar-check"/>
                    April 14, 2023
                </p>
                <h6>Sewa Diwas</h6>
                <p className="event-meta"> 
                    <i className="las la-clock"/>
                    <span>Masi, Almora</span>
                </p>
                <p>Sewa Diwas, celebrated on April 14th, involves the distribution of school supplies and uniforms to underprivileged students. This initiative aims to support education and supply essential goods to individuals facing hardship, demonstrating our dedication to community service.
                </p>
                {/* ... to add events  SimilerEvents */}
            </div>
        </div>
    )
}

export default EventDescription
