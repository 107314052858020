import React from 'react'

function EventDescription() {
    return (
        <div className="col-lg-8 col-md-12">
            <div className="event-details">
                <img src={require('../../../Static/img/chess/groupChess.png')} alt=""/>
                <p className="event-date">
                    <i className="las la-calendar-check"></i>
                    December 3, 2023
                </p>
                <h6>MAASHA Chess Talent Hunt Competition: Nurturing Minds, Crafting Futures</h6>
                <p className="event-meta"> 
                    <span>Dagdi, Masi, Almora</span>
                </p>
                <p>In a spectacular display of intellectual prowess, MAASHA orchestrated a chess symphony that resonated through Somnath Colony, Masi, on December 3, 2023. The "MAASHA Chess Talent Hunt Competition" emerged not only as a platform for strategic battles but as a transformative experience for the young minds of the region.</p>

                <h5>A Stirring Display of Talent:</h5>
                <p>Forty-eight students, comprising 16 girls and 32 boys, converged to engage in an exhilarating contest of wits. The air was thick with anticipation as the chessboard became a battlefield for the keenest minds in the community.
                </p>
                <h5>Triumphant Faces on the Podium:</h5>
                <p>The winners' circle witnessed extraordinary triumphs:
                </p>
                <h7>Boys Category:</h7>
                <p className="pad-lft-15 ">1st Place: Karan Kumar (GIC Patalgaon)<br/>
                    2nd Place: Dikshant Pal (Ramganga Valley Public School)<br/>
                    3rd Place: Gautam Bisht (GIC, Masi)<br/>
                </p>
                <h7>Girls Category:</h7>
                <p className="pad-lft-15">1st Place: Saniya Bisht (GGIC Masi)<br/>
                    2nd Place: Mansi Rawat (GGIC Masi)<br/>
                    3rd Place: Niyati Devtella (Beershiva Public School)
                    <br/>
                </p>
                <h5>Expert Adjudication by Dr. Kapil Gaur:</h5>
                <p>Ensuring a fair and challenging competition, Dr. Kapil Gaur brought his expertise to the judging panel, raising the bar for strategic excellence and supported by a dedicated team including Hemant Gaur, Amit Masiwal, Tanish, Kiran Gaur, and Mamta Baura.
                    <br/>
                </p>
                <h5>MAASHA Sunday Chess Club: A Legacy Begins:</h5>
                <p>Beyond the competition, the event birthed the MAASHA Sunday Chess Club, providing a recurring platform for young talents to refine their skills. This initiative aims not just to play chess but to foster a community bound by a shared love for the game.
                    <br/>
                </p>
                <h5>A Journey Beyond Chessboard Squares:</h5>
                <p>The competition transcended the boundaries of a mere game. It aimed to stimulate mental development, ignite strategic thinking, and build a sense of community among participants. The echoes of success and the infectious enthusiasm of the participants marked the beginning of a transformative journey.
                    <br/>
                </p>
                <h5>Charting the Future:</h5>
            <p>Looking forward, MAASHA envisions a series of regular chess activities. Every Sunday, the MAASHA Sunday Chess Club invites students to participate, ensuring a continuous journey of skill enhancement. Bi-weekly matches are on the horizon, preparing students for upcoming block-level competitions. MAASHA extends an open invitation to chess enthusiasts, parents, and schools to be part of this transformative movement. Chess is not merely a game; it's a journey of intellectual growth and strategic brilliance. Be part of the MAASHA Chess revolution!
                <br/>
            </p>
                <h5>Conclusion:</h5>
                <p>The MAASHA Chess Talent Hunt Competition was not just an event; it was a celebration of intellect, strategy, and community. As the echoes of checkmates fade, the impact lingers, shaping the future of budding minds in Somnath Colony, Masi. Let's continue to make every move count!<br/>
                </p>
                <h5>Media Gallery:</h5>
                    <br></br>
            </div>
        </div>
    )
}

export default EventDescription
