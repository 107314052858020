import React,{useState,useEffect} from 'react'
import portfolio from "../../../components/Constant";

function WorkGallery() {
    const [filter, setFilter] = useState('all');
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(portfolio);
    }, [setProjects]);

    useEffect(() => {
        setProjects([]);
        const filtered = portfolio.map(p => ({ ...p, filtered: p.category.includes(filter) }));
        setProjects(filtered);
    }, [filter]);

    return (
        <div className="event-container">
            <div className="container">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content text-center" data-aos="fade-down">
                                <div className="sec-title">
                                    <h2>Activities Gallery</h2>
                                </div>
                                <ul className="port-menu">
                                    <li active={filter === 'all' ? 'all' : ''} onClick={() => setFilter('all')}>All</li>
                                    <li active={filter === 'Education' ? 'Education' : 'all'} onClick={() => setFilter('Education')}>Education</li>
                                    <li active={filter === 'Environment' ? 'Environment' : 'all'} onClick={() => setFilter('Environment')}>Environment</li>
                                    <li active={filter === 'Health' ? 'Health' : 'all'} onClick={() => setFilter('Health')}>Health</li>
                                    <li active={filter === 'Skill Development and  Rural Livelihoods' ? 'Skill Development and  Rural Livelihoods' : 'all'} onClick={() => setFilter('Skill Development and  Rural Livelihoods')}>Skill Development and  Rural Livelihoods</li>
                                    <li active={filter === 'Community Engagement' ? 'Community Engagement' : 'all'} onClick={() => setFilter('Community Engagement')}>Community Engagement</li>
                                    <li active={filter === 'Capacity Building' ? 'Capacity Building' : 'all'} onClick={() => setFilter('Capacity Building')}>Capacity Building</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-list">
                                {
                                    projects.map(item => item.filtered === true ? (
                                        <div key={item.id} className={`portfolio__container single-portfolio-item ${item.size} ${item.image}`} >
                                            <div className="details">
                                                <div className="info">
                                                    <h5><a href="/#">{item.name}</a></h5>
                                                    <a href={item.image}><i className="las la-search-plus"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : '')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkGallery
