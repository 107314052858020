import React,{Fragment} from 'react'

function AboutORG() {

    const AboutInfo = [
        {
            id:1,
            title:'Our story',
            title2:'Inspiration',
            title3:'Ipsm',
            paragraph:'MAASHA, or Ma Sharda Jan Sewa Samiti, has its roots in a humble beginning. In 1998, a group of four friends united by a shared vision for assisting the underprivileged sector of society founded the organization. Initially operating under the name \'Jai Bhumia Baba Seva Samiti and Seva Ashram,\' they drew inspiration from the philanthropic spirit of Narayan Sewa Sansthan in Udaipur, Rajasthan, which commenced its journey by collecting a simple fistful of wheat flour to aid the needy and underprivileged.',
            image:require('../../../Static/img/inspirationt.jpg')
        },
        {
            id:2,
            title:'About',
            title2:'Mission',
            title3:'About',
            paragraph:'The organization\'s core mission revolves around three key pillars: delivering high-quality education and scholarships, championing environmental conservation and protection, and enhancing healthcare services in rural regions. As we look to the future, our vision is to expand the reach of these initiatives to encompass all districts within Uttarakhand, with the ultimate goal of fostering comprehensive and sustainable development across the entire state.',
            image:''
        }
    ]
    const AboutInfo2 = [
        {
            id:1,
            title:'About',
            title2:'Evolution',
            title3:'Ipsm',
            paragraph:'Over the years, MAASHA has evolved and formalized its commitment to social service. In 2018, it was officially registered as a society under the SOCIETIES REGISTRATION ACT, 1860 (Registration number 137 of 2018), MAASHA is dedicated to providing comprehensive support for sustainable rural development, thereby contributing to the broader goal of achieving lasting progress. In addition to its primary mission, MAASHA also serves as a nurturing platform for the holistic growth of young individuals, empowering them to translate their ideas into tangible positive impacts on society.',
            image:''
        }
    ]

    return (
        <Fragment>
            <section className="about-section bg1">
                <div className="auto-container">
                    <div className="row clearfix mr0">
                        <div className="col-lg-12 pad-bot-25">
                            <div className="about-content text-center">
                                <div className="sec-title">
                                    <h2><span>Our Story</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row pad-top-20">
                            <div className="col-md-6 padlr0">
                                <div className="my-resume">
                                    {
                                        AboutInfo.map((item) => (
                                            <div data-aos="fade-down" key={item.id}>
                                                <div className="resume-item">
                                                    <div className="resume-head">
                                                        <span className="icon-exp"><i className=" fa fa-heart"/></span>
                                                        <div className="content-exper">
                                                            <h4>{item.title2}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="resume-footer">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                    {
                                                    item.image === '' ? 
                                                        "" : 
                                                        <div className="img-resume">
                                                            <img src={item.image} alt="my-history"/>
                                                        </div> 
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 padlr0">
                                <div className="my-resume">
                                    {
                                        AboutInfo2.map((item) => (
                                            <div data-aos="fade-down" key={item.id}>
                                                <div className="resume-item">
                                                    <div className="resume-head">
                                                        <span className="icon-exp"><i className=" fa fa-heart"/></span>
                                                        <div className="content-exper">
                                                            <h4>{item.title2}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="resume-footer">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                    {
                                                        item.image === '' ? '' : <div className="img-resume">
                                                            <img src={item.image} alt="my-history"/>
                                                        </div> 
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default AboutORG
