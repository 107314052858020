import React from 'react'

function About() {
    return (
        <section className="about-section aboutBG">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="image-column col-md-6 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <figure className="image-1"><img data-aos="fade-right" src={require('../../../Static/img/masi.JPG')} className="abt-cor-img2" alt=""/></figure>
                        </div>
                    </div>
                    <div className="content-column pull-right col-md-6 col-sm-12 col-xs-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                <h2 data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="600">About Us<br></br><span>Our Story</span></h2>
                            </div>
                            <br></br>
                            <div className="text" data-aos="fade-up">MAASHA, or Ma Sharda Jan Sewa Samiti, has its roots in a humble beginning. In 1998, a group of four friends united by a shared vision for assisting the underprivileged sector of society founded the organization. Initially operating under the name 'Jai Bhumia Baba Seva Samiti and Seva Ashram,' they drew inspiration from the philanthropic spirit of Narayan Sewa Sansthan in Udaipur, Rajasthan, which commenced its journey by collecting a simple fistful of wheat flour to aid the needy and underprivileged.
                                <br/>
                                <br/>
                                Over the years, MAASHAA has evolved and formalized its commitment to social service. In 2018, it was officially registered as a society under the SOCIETIES REGISTRATION ACT, 1860 (Registration number 137 of 2018), MAASHA is dedicated to providing comprehensive support for sustainable rural development, thereby contributing to the broader goal of achieving lasting progress. In addition to its primary mission, MAASHA also serves as a nurturing platform for the holistic growth of young individuals, empowering them to translate their ideas into tangible positive impacts on society.
                                <br/>
                                <br/>
                                The organization's core mission revolves around three key pillars: delivering high-quality education and scholarships, championing environmental conservation and protection, and enhancing healthcare services in rural regions. As we look to the future, our vision is to expand the reach of these initiatives to encompass all districts within Uttarakhand, with the ultimate goal of fostering comprehensive and sustainable development across the entire state.
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
