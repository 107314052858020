import React from 'react'
import Breadcroumb from '../../components/Breadcroumb/Breadcroumb'
import BeforeFooterSection from '../../components/BeforeFooterSection/BeforeFooterSection'
import withScrollHOC from '../../HOC/ScrollHOC'
import PrimaryBtn from "../../components/Buttons/PrimaryBtn";

function Contactus() {

    return (
        <div>
            <Breadcroumb name="Contact Us"/>
            <div className="contact-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-content text-center" data-aos="fade-down">
                                <div className="sec-title">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="contact-detail">
                                        <p><i className="las la-map-marker"/><b>Address</b>
                                            <span> Maa Sharda Jan Seva Samiti, </span>
                                            <span>Masi, Almora, Uttarakhand 263658, India</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-detail">
                                        <p><i className="las la-mobile"/><b>Contact</b>
                                            <span>+91 8171233001</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-detail">
                                        <p><i className="las la-envelope"/><b>Email</b>
                                            <span>support@maashaa.org</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="map-area map-padding pad-bot-50">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="map-section">
                                                        <iframe title="ORG Location"
                                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.6792331960714!2d79.28100459999997!3d29.81581005000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909fbfc56e2475f%3A0x380ac11bfbc24fdf!2sMaa%20Sharda%20Jan%20Seva%20Samiti!5e0!3m2!1sen!2sin!4v1699860326446!5m2!1sen!2sin"
                                                            width="600" height="450" frameBorder="0" style={{border:'0'}}
                                                            allowFullScreen="" aria-hidden="false" tabIndex="0">
                                                        </iframe> 
                                                        <i className="las la-map-marker"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-form ">
                                        <form name="contact-form" id="contactForm" netlify>
                                            <input type="text" name="name" id="name" required="" placeholder="User Name"/>
                                            <input type="email" name="email" id="email" required="" placeholder="Your E-mail"/>
                                            <input type="text" name="subject" id="subject" placeholder="Subject"/>
                                            <textarea name="message" id="message" cols="30" rows="10" required=""
    placeholder="How can help you?"/>
                                            <PrimaryBtn type="submit" ButtonText="Send Message"/>
                                        </form>
                                    </div>
                                </div>
                                <div className="text" data-aos="fade-down">
                                    <span className="title">Disclaimer</span>
                                    <p>Please be advised that no third-party agency is authorized to act on behalf of Maasha for matters related to recruitment, training, camps, or other activities. For accurate information or assistance, kindly verify directly with Maasha. We urge caution against any unauthorized claims made on our behalf. Maasha does not charge any fees for these activities. If you encounter any individual or organization seeking money in our name, please promptly report it to us at support@maashaa.org for immediate attention and action.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BeforeFooterSection/>
        </div>
    )
}

export default withScrollHOC(Contactus)
