import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import {Header, Footer} from './components';
import {Home, EventContainer, OurWork, Contactus, AboutUs} from './container';
import EventDetails1 from "./container/EventDetails/EventDetails1";
import EventDetails2 from "./container/EventDetails2/EventDetails2";
import EventDetails3 from "./container/EventDetails3/EventDetails3";
import EventDetails4 from "./container/EventDetails4/EventDetails4";

function App() {
    useEffect(() => {
        Aos.init({duration: 200});
    }, []);
    return (
        <div>
            <Router>
                <Header/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<AboutUs/>}/>
                    <Route path="/events" element={<EventContainer/>}/>
                    <Route path="/pariksha" element={<EventDetails1/>}/>
                    <Route path="/chess" element={<EventDetails4/>}/>
                    <Route path="/marathon" element={<EventDetails3/>}/>
                    <Route path="/sewa-diwas" element={<EventDetails2/>}/>
                    <Route path="/ourwork" element={<OurWork/>}/>
                    <Route path="/contact" element={<Contactus/>}/>
                </Routes>
                <Footer/>
            </Router>
            {/* Footer Section */}

        </div>
    );
}

export default App;
