import React, { Fragment } from 'react'
import EventDescription from './Components/EventDescription'
import Breadcroumb from '../../components/Breadcroumb/Breadcroumb'
import withScrollHOC from '../../HOC/ScrollHOC'
import SimilerEvents from './Components/SimilerEvents';


function EventDetails4() {
    return (
        <Fragment>
            <Breadcroumb name="Event Details"/>
            <div className="event-area section-padding">
                <div className="container">
                    <div className="row">
                        <EventDescription/>
                        <SimilerEvents/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withScrollHOC(EventDetails4)
