import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export function HeroSlider() {
        const content = [
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'"Nature is not a place to visit. It is home". - Gary Snyder',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item1 single-slide-item',
            },
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'Elders are the true storytellers, keepers of traditions, and the heart of the family.',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item2 single-slide-item',
            },
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'"The groundwork of all happiness is good health." - Leigh Hunt',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item3 single-slide-item',
            },
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'Recognizing talent is not just about identifying skills; it\'s about understanding potential and nurturing it to flourish.',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item4 single-slide-item',
            },
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'"Music is the universal language of mankind." - Henry Wadsworth Longfellow',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item5 single-slide-item',
            },
            {
                smallTitle: 'Charity is Everywhere.',
                mainTitle_normalColor:'Life\'s a marathon, not a sprint. Pace yourself, enjoy the journey, and celebrate every step — true victory is in the endurance, not just the finish line.',
                mainTitle_BreakTitle:'to Bring ',
                mainTitle_PrimaryColor:'Happiness',
                button: 'Donate Now',
                image: 'single-slide-item6 single-slide-item',
            }
        ];
        return (
            <OwlCarousel
                className="homepage-slides owl-carousel"
                loop={true}
                autoplay={true}
                items={1}
                nav 
                dots={false}
                responsiveRefreshRate >
                {
                    content.map((item, index) => (
                        <div key={index}>
                            <div className={item.image}>
                                <div className="hero-area-content">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12 text-center wow fadeInUp animated" data-wow-delay=".2s">
                                                <div data-aos="fade-down" className="section-title">
                                                    <h5>{item.mainTitle_normalColor}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </OwlCarousel>
        )
    
}

export default HeroSlider;
