import React, { Fragment } from 'react'
import EventDescription from './Components/EventDescription'
import Breadcroumb from '../../components/Breadcroumb/Breadcroumb'
import withScrollHOC from '../../HOC/ScrollHOC'

function EventDetails2() {
    return (
        <Fragment>
            <Breadcroumb name="Event Details"/>
            <div className="event-area section-padding">
                <div className="container">
                    <div className="row">
                        <EventDescription/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withScrollHOC(EventDetails2)
