import React from 'react'

function Breadcroumb({name="home"}) {
    return (
        <div className="breadcroumb-area bread-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcroumb-title text-center">
                            <h1>The future of India lies in its villages - Mahatma Gandhi</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcroumb
