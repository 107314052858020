import React from 'react'

function AreaOfWork() {

    const AreaofWork = [
        {
            id:1,
            image:require('../../../Static/img/pariksha.jpg'),
            title:'Education',
            description: 'Empower individuals with accessible, inclusive, and quality education, enabling them to unlock their full potential and contribute to societal progress.'
        },
        {
            id:2,
            image:require('../../../Static/img/swachta.jpg'),
            title:'Environment',
            description: 'Promote eco-conscious practices and collaborate with local communities to preserve biodiversity and mitigate environmental challenges, ensuring a greener and more sustainable planet for future generations.'
        },
        {
            id:3,
            image:require('../../../Static/img/health.JPG'),
            title:'Health',
            description: 'Promote holistic health and well-being by providing essential healthcare services, preventative care, and health education, thus ensuring a healthy and thriving community.'
        },
        {
            id:4,
            image:require('../../../Static/img/livelihood.jpg'),
            title:'Skill Development and  Rural Livelihood',
            description: 'Facilitate economic self-sufficiency and sustainable livelihoods through skill development, vocational training, and entrepreneurship initiatives, empowering individuals to improve their standard of living.'
        },
        {
            id:5,
            image:require('../../../Static/img/chess.jpeg'),
            title:'Capacity Building',
            description: 'Strengthen the capacities of individuals, communities, and local institutions to become self-reliant, resilient, and capable of driving their own sustainable development.'
        },
        {
            id:6,
            image:require('../../../Static/img/community.JPG'),
            title:'Community Engagement',
            description: 'Foster active community participation and ownership in the development process, promoting a sense of ownership and pride in the progress and well-being of their communities.'
        }
    ]

    return (
        <div className="event-container">
            <div className="auto-container ">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-content text-center" data-aos="fade-down">
                            <div className="sec-title">
                                <h2>Areas of work</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {
                        AreaofWork.map((items) => (
                            <div className="col-lg-6 col-md-6" key={items.id}>
                                <div className="each-case-2">
                                    <div className="case-2-img">
                                        <a href="/#"><img src={items.image} alt=""/></a>
                                    </div>
                                    <div className="case-2-texts bg-light-white">
                                        <h3 className="cl1">{items.title}</h3>
                                        <p>{items.description} 
                                            <span><a href="/#" className="ebtn">&nbsp; Explore 
                                            <i className="fa fa-arrow-right"/> </a></span></p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AreaOfWork
