import React from 'react'

function SimilerEvents() {

    const similerEvents = [
        {
            id:1,
            day:'04',
            month:'June',
            title:'Event Title',
            description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit rerum culpa molestiae impedit, incidunt minus nesciunt reiciendis quo exercitationem eligendi eius vero debitis dolores laboriosam blanditiis fugiat iure pariatur eveniet.',
            image:require('../../../Static/img/Marathon newspaper.jpg')
        },
        {
            id:2,
            day:'04',
            month:'June',
            title:'Event Title',
            description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit rerum culpa molestiae impedit, incidunt minus nesciunt reiciendis quo exercitationem eligendi eius vero debitis dolores laboriosam blanditiis fugiat iure pariatur eveniet.',
            image:require('../../../Static/img/marathon newspaper 2.jpg')
        },
        {
            id:3,
            day:'04',
            month:'June',
            title:'Event Title',
            description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit rerum culpa molestiae impedit, incidunt minus nesciunt reiciendis quo exercitationem eligendi eius vero debitis dolores laboriosam blanditiis fugiat iure pariatur eveniet.',
            image:require('../../../Static/img/marathon newspaper 3.jpg')
        }
    ]

    return (
        <div>
            <div className="content-side col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="event-grid">
                    <div className="row clearfix">
                        {
                            similerEvents.map((items,index) => (
                                <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2" key={items.id}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <a href="/#"><img src={items.image} alt=""/></a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimilerEvents
