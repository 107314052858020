const picsGalleryMarathon = [
    {
        id:1,
        size: 'small',
        image:'port-bg-1',
        name: 'Maashaa',
        category: ['all', 'Education', 'Environment']
    },
    {
        id:2,
        size: 'small',
        image:'port-bg-2',
        name: 'Maashaa',
        category: ['all', 'Health', 'Environment'],
    },
    {
        id:3,
        size: 'small',
        image:'port-bg-3',
        name: 'Maashaa',
        category: ['all', 'Education'],
    },
    {
        id:4,
        size: 'small',
        image:'port-bg-5',
        name: ['Maashaa'],
        category: ['all', 'Skill Development and  Rural Livelihoods'],
    },
    {
        id:5,
        size: 'small',
        image:'port-bg-4',
        name: 'Maashaa',
        category: ['all', 'Education', 'Community Engagement'],
    },
    {
        id:5,
        size: 'small',
        image:'port-bg-6',
        name: 'Maashaa',
        category: ['all', 'Education', 'Community Engagement'],
    }
];

export default picsGalleryMarathon