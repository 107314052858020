import React from 'react'

function Achivements() {
    return (
        <div className="achievement-area">
            <div className="container">
                <div className="col-lg-12 text-center">
                    <div className="sec-title">
                        <span className="title cl3">Our Impact</span>
                    </div>
                </div>
                <br></br>

            </div>
        </div>
    )
}

export default Achivements
