import React, { Component } from "react";
import Faq from "react-faq-component";
 
const data = {
    title: "FAQ",
    rows: [
        {
            title: "What is MAASHA, and what is its mission?",
            content: `MAASHA, which stands for Ma Sharda Jan Sewa Samiti, is a nonprofit organization dedicated to improving the lives of underprivileged communities in Uttarakhand. Our mission is to provide comprehensive support for sustainable rural development, focusing on education, environmental conservation, healthcare, and empowering the youth to create positive change.`,
        },
        {
            title: "How can I volunteer/Internship with MAASHA?",
            content:
                "We welcome enthusiastic volunteers who share our commitment to social service. You can join us in various capacities, such as teaching, healthcare, environmental initiatives, and more. Contact us to inquire about current volunteer opportunities.",
        },
        {
            title: "Can I donate to MAASHA, and how will my donation be used?",
            content: `Yes, you can donate to MAASHA to support our initiatives. Your donation will be used to fund education programs, environmental projects, healthcare services, and overall community development in Uttarakhand. We ensure that every donation is directed towards creating a positive impact.`,
        },
        {
            title: "How can I stay updated on MAASHA's work and initiatives?",
            content: `You can stay informed about our latest projects, events, and success stories by subscribing to our newsletter. We also share updates on our website and social media channels, so be sure to follow us on platforms like Facebook and Twitter.`,
        },
        {
            title: "Are there any opportunities for partnership with MAASHA?",
            content: `Yes, we actively seek partnerships with like-minded organizations, businesses, and individuals who share our mission. Collaborate with us on projects, events, or initiatives aligned with our goals to amplify our impact together.`,
        },
        {
            title: "Is MAASHA a registered organization?",
            content: `Yes, MAASHA is officially registered as a society under the SOCIETIES REGISTRATION ACT, 1860 (Registration number UK06408092018000461 of 2018).`,
        },
    ],
};
 
const styles = {
    bgColor: 'tranperent',
    titleTextColor: "#f9004d",
    rowTitleColor: "black",
    rowContentColor: 'grey',
    arrowColor: "#f9004d"
};
 
const config = {
    animate: true,
    // arrowIcon: "V",
};
 
export default class FAQ extends Component {
    render() {
        return (
            <section className="about-section aboutBG bg1">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column  pull-right col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title" data-aos="fade-right">
                                    <h3><br/>Frequently asked questions</h3>
                                </div>
                                <br></br>
                                <div className="text" data-aos="fade-right">MAASHA, Ma Sharda Jan Sewa Samiti, started in 1998 with a shared vision to help the underprivileged. In 2018, we officially registered as a society. Our mission includes education, environmental conservation, and healthcare in rural areas. We empower young individuals to create positive impacts. Our future goal is to expand our initiatives across all districts in Uttarakhand for comprehensive development.</div>
                            </div>
                        </div>
                        <div className="image-column col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <Faq data={data} styles={styles} config={config} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}