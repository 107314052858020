import React from 'react'
import { Link } from 'react-router-dom';

function EventsAll() {

    const eventsData = [
        {
            key:1,
            id:1,
            eventImage:require('../../../Static/img/pariksha.jpg'),
            eventTitle:'Khetriya Gyan Vigyan Pariksha',
            eventTime:'1:00pm to 9:00 AM',
            eventLocation:' Masi, Almora',
            eventPage:"/pariksha",
            eventDescription:'On 15 September ‘kshetryiya gyan vigyan parisksha’ competition for school students to test the holistic understanding. Students from all the schools in the Chaukhutiya block participates in the competition.'
        },
        {key:2,
            id:2,
            eventImage:require('../../../Static/img/sevaDiwas.JPG'),
            eventTitle:'Sewa Diwas',
            eventTime:'1:00pm to 9:00pm',
            eventLocation:' Masi, Almora',
            eventPage:"/sewa-diwas",
            eventDescription:'On Sewa Diwas, observed on April 14th, we distribute school supplies and uniforms, extending support to education, and provide essential goods to those in need, embodying our commitment to community service.'
        },
        {   key:3,
            id:3,
            eventImage:require('../../../Static/img/marathon.jpg'),
            eventTitle:'अलख Run for Pahad',
            eventTime:'1:00pm to 9:00pm',
            eventLocation:' Gewar Ghati, Masi, Almora',
            eventPage:"/marathon",
            eventDescription:'we proudly organized the inaugural \'Alakh Run for Pahad\' marathon, a significant event aimed at catalyzing positive transformation in the region and promoting tourism in remote villages. The marathon was conducted successfully and a lot of media attention across various social media platforms.'
        },
        {
            id:4,
            eventImage:require('../../../Static/img/photographyEvent.jpg'),
            eventTitle:'Photography Competition',
            eventTime:'October 20, 2019',
            eventLocation:'Gewar Ghati, Masi',
            eventDescription:'Concurrently with the exhilarating marathon, a captivating photography competition unfolded, and the Pandavaas Group  took on a pivotal role in conducting and overseeing the event. Their contribution added an enriching artistic dimension to the festivities, showcasing the diverse perspectives captured through the lenses of talented photographers.'
        },
        {
            id:4,
            eventImage:require('../../../Static/img/livelihood.jpg'),
            eventTitle:'Livelihood programme',
            eventTime:'5 to 8 July 2020',
            eventLocation:'Naugaon, Masi',
            eventDescription:' This initiative aimed to not only raise the income levels of participating farmers but also to diversify their sources of revenue. The training was expertly conducted under the guidance of Mrs. Preeti Bhandari, renowned as the Mushroom Lady of Almora. Her expertise and experience in mushroom farming added significant value to the training, providing farmers with valuable insights and practical skills to engage in sustainable and profitable mushroom cultivation.'
        },
        {
            id:6,
            eventImage:require('../../../Static/img/environment.jpg'),
            eventTitle:'Painting Competition',
            eventTime:'December 3, 2023',
            eventLocation:'Bhumiya Mandir, Masi',
            eventDescription:'On the occasion of Environment Day, we took a proactive approach to instill a sense of environmental consciousness among students by organizing a captivating painting competition. This initiative aimed to not only showcase the creative talents of the school students but also to raise awareness about the pressing importance of environmental conservation. To further drive home the message, each participating student received a plant, symbolizing the commitment to saving trees and fostering a sustainable environment.'
        },
        {
            id:6,
            eventImage:require('../../../Static/img/swachta.jpg'),
            eventTitle:'Swachhata Abhiyan',
            eventTime:'December 3, 2023',
            eventLocation:'Gewar Ghati, Masi',
            eventDescription:'Swachhata Abhiyan was launched in Masi, underscoring our dedication to ensuring a clean and healthy environment. The enthusiastic participation of various volunteers from the Masi community added a collective strength to the Abhiyan, resulting in its undeniable success.'
        },
        {
            id:5,
            eventImage:require('../../../Static/img/chess.jpeg'),
            eventTitle:'MAASHA Chess Talent Hunt',
            eventTime:'December 3, 2023',
            eventLocation:' Somnath Colony, Masi',
            eventPage:"/chess",
            eventDescription:'In the heart of Masi, where tradition and progress coalesce, an intellectual spectacle unfolded – the MAASHA Chess Talent Hunt Competition. It wasn\'t merely a chess competition; it was an ode to strategic thinking, a celebration of intellectual prowess, and a testament to the indomitable spirit of the youth.'
        }
    ]

    const infos = {
        infoTitle: 'Events',
        paragraph:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas eligendi vitae perferendis voluptatum voluptatibus dolores architecto doloribus excepturi natus facere voluptates, nesciunt consequatur.'
    }
    
    return (
        <section id="showevent">
            <div className="event-container">
                <div className="container">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-content text-center" data-aos="fade-down">
                                    <div className="sec-title">
                                        <h2>{infos.infoTitle}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            eventsData.map((item) => (
                                <div className="col-md-4 mt-4" key={item.id}>
                                    <div className="card ecard event-card">
                                        <div className="card-img-block">
                                        <Link to={item.eventPage}>
                                            <img className="card-img-top" 
                                                src={item.eventImage}
                                                alt="event"/>
                                            </Link>
                                        </div>
                                        <div className="card-body pt-0">
                                            <h5 className="card-title">
                                                <Link to={item.eventPage}
                                                    href="/#" 
                                                    className="event_a">
                                                    {item.eventTitle}
                                                </Link>
                                            </h5>
                                            <h6 className="cl2"> &nbsp;&nbsp;<i
                                                    className="fa fa-map-marker cl1"/> {item.eventLocation} </h6>
                                            <p>{item.eventDescription}</p>
                                            <h6>
                                                <Link to={item.eventPage}
                                                    className="cl2">
                                                        Read more 
                                                </Link>
                                                &nbsp;
                                                <i className="fa fa-arrow-right cl1"/>
                                            </h6>
                                        </div>
                                    </div>
                                    <p className="mt-3 w-100 float-left text-center"/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default  EventsAll;