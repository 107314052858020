import React from 'react'
import SimilerEvents from './SimilerEvents'

function EventDescription() {
    return (
        <div className="col-lg-8 col-md-12">
            <div className="event-details">
                <img src={require('../../../Static/img/pariksha.jpg')} alt=""/>
                <p className="event-date">
                    <i className="las la-calendar-check"></i>
                    September 15, 2023
                </p>
                <h6>Kshetryiya Gyan Vigyan Parisksha</h6>
                <p className="event-meta"> 
                    <span>SSM, Masi, Almora</span>
                </p>
                <p>Annually, the 'Kshetryiya Gyan Vigyan Parisksha' competition is meticulously arranged with the primary objective of evaluating the extensive comprehension of diverse subjects among school students. This eagerly anticipated event brings together participants from every school within the Chakutiya Block, providing them with a unique platform to showcase their multifaceted knowledge and proficiency across various academic disciplines. It serves as an opportunity for students to not only display their understanding but also encourages a spirit of healthy academic competition and overall development.
                </p>
                <br></br>
                <SimilerEvents/>
            </div>
        </div>
    )
}

export default EventDescription
