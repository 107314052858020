import React from 'react'
import OwlCarousel from 'react-owl-carousel';

function OurMission() {
    const content = [
        {   
            id:1,
            image: require('../../../Static/img/gallaryAbout/imageGallary.jpg')
        },
        {
            id:2,
            image: require('../../../Static/img/gallaryAbout/Marathon.jpg')
        },
        {
            id:3,
            image: require('../../../Static/img/inspirationt.jpg')
        },
        {
            id:3,
            image: require('../../../Static/img/livelihood.jpg')
        }
    ];
    return (
        <div>
            <section className="about-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-column pull-right col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-column">
                                <div className="sec-title" data-aos="fade-down">
                                    <h2>What we do </h2>
                                </div>
                                <br/>
                                <div className="text" data-aos="fade-down">
                                    <p>Through our unwavering commitment to education, health, livelihood, and environmental sustainability, we aim to create a world where every individual has the opportunity to lead a dignified, healthy, and fulfilling life and where communities thrive in harmony with the environment. Together, we can build a better future for all.
                                        <br/><br/>MASHAA is dedicated to fostering holistic rural development through a self-sustaining ecosystem-based approach. Our efforts are characterized by a long-term, interconnected strategy encompassing various activities, programs, and events. We prioritize focusing on these communities' most vulnerable and marginalized families, aiming to uplift and empower them toward sustainable progress.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="image-column col-lg-6 col-md-12 col-sm-12 col-xs-12 corner">
                            <div id="carousel-example-1z" data-aos="fade-up" className="carousel slide carousel-fade shadow" data-ride="carousel">
                                <OwlCarousel className="logo-carousel owl-carousel"
                                    loop={true}
                                    autoplay={true}
                                    items={1}
                                    dots={false}>
                                        {
                                            content.map((item) => (
                                                <div className="carousel-item active" key={item.id}>
                                                    <img className="d-block w-100"
                                                        src={item.image} alt="First slide"/>
                                                </div>
                                            ))
                                        }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurMission
